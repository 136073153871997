import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categories: [],
    activeCategory: {}
}

export const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setActiveCat: (state, action) => {
            state.activeCategory = action.payload;
        }
    }
});

export const { setActiveCat, setCategories} = categorySlice.actions;
export default categorySlice.reducer;