import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logedInUser: null,
    myOrders : [],
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLogedInUser : (state, action)=>{
            state.logedInUser = action.payload;
        },
        setMyOrders: (state, action) => {
            state.myOrders = action.payload;
        }

    }
});

export const { setLogedInUser, setMyOrders } = userSlice.actions;
export default userSlice.reducer;