import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartItems: [],
    orderType: ""
}

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addCartItem: (state, action) => {
            const existingProductIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
            if (existingProductIndex !== -1) {
                // Product already exists in the cart, update its quantity
                state.cartItems[existingProductIndex].q += action.payload.q;
                state.cartItems[existingProductIndex].p += action.payload.p;
            } else {
                // Product doesn't exist in the cart, add it
                state.cartItems.push(action.payload);
            }
        },
        removeCartItem: (state, action) => {
            // Remove the item from the cart
            state.cartItems = state.cartItems.filter((cart) => cart.id !== action.payload);

        },
        setOrderType: (state, action) => {
            state.orderType = action.payload;
        },
        setCartItem: (state, action) => {
            state.cartItems = action.payload
        }
    }
});

export const { addCartItem, removeCartItem, setOrderType, setCartItem } = cartSlice.actions;
export default cartSlice.reducer;
