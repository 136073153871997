import React from 'react';
import { Layout, Menu } from 'antd';
import "./style.css"
import logo from "../../../assets/images/Color-logo.png"
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCat } from '../../../store/features/category/categorySlice';

const { Sider } = Layout;

const SideBar = ({ closeDrawer }) => {

    // getting all categorys from redux 

    const { categories: menuItems, activeCategory } = useSelector((state) => state.category);
    const categories = menuItems.filter((item) => item.cname !== "Salads");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = (item) => {
        // on menu item click setting active category to redux store to get prodcuts according to active category 
        dispatch(setActiveCat({ catName: item.cname, catId: item.cid }));
        //in mobile menu closeDrawer work to close drawer when click on menu item
        if (closeDrawer) {
            closeDrawer();
        }
        navigate("/dashboard");
    };


    return (
        <Sider id='dashboardSidebar' className='dashboardSidebar' style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            background: 'black'
        }}>
            <Link to='/dashboard'>
                <div className="sideBarHeader p-4 text-center">
                    <img src={logo} alt="leflamme logo" width={180} />
                </div>
            </Link>
            <Menu className='sideBarMenu' theme="dark" mode="inline">
                {categories && categories.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={() => handleClick(item)} className={activeCategory.catName === item.cname ? "active-item" : ""} >
                            <p className='m-0 p-0'>{item.cname}</p>
                        </Menu.Item>
                    )
                })}
            </Menu>
        </Sider>
    )
}

export default SideBar;
