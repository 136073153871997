import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const NewPassword = () => {

    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${BASE_URL}/updatepassword.php`, { email, token, pass: newPassword });
            if (response.status === 200) {
                toast.success(`Password Updated Successfully`, {
                    position: 'top-center',
                    theme: "dark",
                    autoClose: 1300
                });
                navigate("/dashboard/signin");
            }
        } catch (error) {
            toast.error(`Something went wrong`, {
                position: 'top-center',
                theme: "dark",
                autoClose: 1300
            });
        }
    }

    return (
        <div className='forgot-password-form'>
            <h4 className='fw-normal'>New Password</h4>
            <div className="container my-5">
                <div className="row justify-content-center align-item-center">
                    <div className="col-md-6">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="code"
                                    placeholder="Enter Code"
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="newPassword"
                                    placeholder="Enter New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="theme-btn  mb-3 w-100">Update Password</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NewPassword