import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Welcome from './components/welcome/Welcome';
import Dashboard from './main/dashboard/Dashboard';
import { useEffect, useState } from 'react';
import useFetch from './hooks/useFetch';
import { useDispatch, useSelector } from 'react-redux';
import { setExtras, setFlavours, setOptions, setProducts, setSides } from './store/features/products/productSlice';
import { setActiveCat, setCategories } from './store/features/category/categorySlice';
import Preloader from './components/Preloader/Preloader';
import PageNotFound from './components/pageNotFound/PageNotFound';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setMyOrders } from './store/features/user/userSlice';
import axios from 'axios';

function App() {
  const [showPreloader, setShowPreloader] = useState(true);
  const { loggedInUser } = useSelector((state) => state.user);
  const { orderType } = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const { data: productsData, error: productsError } = useFetch("/pro.php");
  const { data: categoriesData } = useFetch("/cat.php");
  const { data: sides } = useFetch("/sides.php");
  const { data: flavours } = useFetch("/flavours.php");
  const { data: options } = useFetch("/options.php");
  const { data: extras } = useFetch("/extras.php");

  useEffect(() => {
    if (productsData && categoriesData && flavours && sides && !productsError && options && extras) {
      dispatch(setProducts(productsData.data));
      dispatch(setOptions(options));
      dispatch(setCategories(categoriesData.data));
      dispatch(setSides(sides.data));
      dispatch(setFlavours(flavours.data));
      dispatch(setExtras(extras.data));

      const catId = categoriesData.data[0]?.cid; // Use optional chaining to avoid errors if categoriesData.data is empty
      const catName = categoriesData.data[0]?.cname;
      dispatch(setActiveCat({ catId, catName }));

      setShowPreloader(false);
    }
  }, [productsData, categoriesData, flavours, sides, productsError, options, extras, dispatch]);

  useEffect(() => {
    if (loggedInUser) {
      const { data: myOrderData } = axios.get(`${process.env.REACT_APP_BASE_URL}/getbillsbyclient.php?id=${loggedInUser.id}`);
      if (myOrderData) {
        dispatch(setMyOrders(myOrderData.data));
      }
    }
  }, [loggedInUser, dispatch]);

  if (showPreloader) {
    return <Preloader />;
  }

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={loggedInUser ? <Navigate to="/dashboard" /> : <Welcome />} />
        <Route path="/dashboard/*" element={orderType ? <Dashboard /> : <Navigate to="/" />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
