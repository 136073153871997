import React, { useState } from 'react';
import { Layout, Drawer } from 'antd';
import { FiShoppingCart, FiMenu } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import './style.css';
import SideBar from '../sidebar/SideBar';
import { useDispatch, useSelector } from 'react-redux';
import { setLogedInUser } from '../../../store/features/user/userSlice';
import { setCartItem, setOrderType } from '../../../store/features/cart/cartSlice';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';

const { Header } = Layout;

const Topbar = () => {

    const [drawerVisible, setDrawerVisible] = useState(false);
    const { cartItems } = useSelector((state) => state.cart);
    const { logedInUser, myOrders } = useSelector((state) => state.user);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };
    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const collectionFrom = "Wolvernamption";

    const handeLogout = () => {
        handleClose();
        dispatch(setLogedInUser(null));
        dispatch(setCartItem([]));
        dispatch(setOrderType(null));
        toast.success(`Logout Successfully`, {
            position: 'top-center',
            theme: "dark",
            autoClose: 1300
        });
        navigate("/");
    }


    return (
        <>
            <Header
                style={{
                    padding: 0,
                    background: "#1F1F1F",
                    zIndex: "2",
                    position: "fixed",
                    width: "-webkit-fill-available",
                    height: "auto"
                }}
                className='dashboardTopbar'
            >
                <div className="header-content d-flex justify-content-between align-items-center  px-4 py-3 text-white">
                    <div className="header-left-content d-flex align-items-center">
                        <div className='me-5'>
                            <p className='m-0 p-0 fw-medium mb-1'>Collection From</p>
                            <p className='m-0 p-0 d-block fw-light'>{collectionFrom}</p>
                        </div>
                        <div>
                            <p className="m-0 p-0 fw-medium mb-1">Estimated Collection time</p>
                            <p className='m-0 p-0 d-block fw-light'>15 Minutes</p>
                        </div>
                    </div>
                    <div className="header-right-content d-flex align-items-center">
                        {/* by default display none show it on less than 991px  */}
                        <div className="menu-icon me-3 cursor-pointer d-none" id='sideBarToggler' onClick={toggleDrawer}>
                            <FiMenu />
                        </div>
                        <div className="actions d-flex align-items-center">
                            <div className="cart-icon me-3">
                                <Link to="/dashboard/cart" className='text-decoration-none text-white'> <FiShoppingCart /> <span>{cartItems && cartItems.length}</span></Link>
                            </div>
                            {logedInUser ?
                                <div>
                                    {myOrders && myOrders.length > 0 && <Link to="/dashboard/myorders" className='text-decoration-none text-white me-2'><button className="theme-btn">My Orders</button></Link>}
                                    <button onClick={handleShow} className="theme-btn text-decoration-none text-white">Logout</button>
                                </div>
                                :
                                <div>
                                    <Link to="/dashboard/signin" className="me-3 text-decoration-none text-white">
                                        <button className="theme-btn text-decoration-none text-white">Signin</button>
                                    </Link>
                                    <Link to="/dashboard/register" className="text-decoration-none text-white">
                                        <button className="theme-btn text-decoration-none text-white">Register</button>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Header>
            <Drawer
                title="Drawer Title"
                placement="left"
                closable={false}
                onClose={toggleDrawer}
                open={drawerVisible}
                width={250}
            >
                <SideBar closeDrawer={closeDrawer} />
            </Drawer>


            <div className="logoutModal">
                <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered className="text-white">
                    <Modal.Header className="bg-dark py-5 text-center d-block border-0">
                        <Modal.Title>Do you want logout ?</Modal.Title>
                        <div className='mt-4'>
                            <Button variant="secondary" className='me-3' onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" className='theme-btn' onClick={handeLogout}>
                                Logout
                            </Button>
                        </div>
                    </Modal.Header>
                </Modal>
            </div>


        </>
    );
};

export default Topbar;
