import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./style.css"
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLogedInUser } from '../../store/features/user/userSlice';
import { toast } from 'react-toastify';

const SignIn = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginUser = async (e) => {
        e.preventDefault();
        try {
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${BASE_URL}/customerlogin.php`, { email, password });
            if (response.status === 200) {
                dispatch(setLogedInUser(response.data.user_data));
                toast.success("Logged In Successfully", {
                    position: 'top-center',
                    theme: "dark",
                    autoClose: 1300
                });
                navigate("/dashboard");
            } else {
                toast.error("Invalid Credentials", {
                    position: 'top-center',
                    theme: "dark",
                    autoClose: 1300
                });
            }
        } catch (error) {
            if (error.response.status === 401) {
                toast.error("Invalid Credentials", {
                    position: 'top-center',
                    theme: "dark",
                    autoClose: 1300
                });
            } else {
                console.log(error.message);
            }
        }
    };

    return (
        <div className='signin-form'>
            <h4 className='fw-normal'>Sign In</h4>
            <div className="container my-5">
                <div className="row justify-content-center align-item-center">
                    <div className="col-md-6">
                        <form onSubmit={loginUser}>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                <Link to="/dashboard/forgotpassword" className="text-decoration-none text-white fw-medium">Forgot Password?</Link>
                            </div>
                            <button type="submit" className="theme-btn  mb-3 w-100">Sign In</button>
                        </form>
                        <p className="text-center mb-0">Not registered yet? <Link to="/dashboard/register" className='text-white fw-medium'>Click here to register</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
