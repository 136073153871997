import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    sides: [],
    flavours: [],
    productsOptions: null,
    extras: []
}

export const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setSides: (state, action) => {
            state.sides = action.payload;
        },
        setFlavours: (state, action) => {
            state.flavours = action.payload;
        },
        setOptions: (state, action) => {
            state.productsOptions = action.payload;
        },
        setExtras: (state, action) => {
            state.extras = action.payload;
        }
    }
});

export const { setProducts, setSides, setFlavours, setOptions, setExtras } = productsSlice.actions;
export default productsSlice.reducer;
