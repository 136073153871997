import React from 'react'
import { Link } from 'react-router-dom'

const ContentNotFound = () => {
    return (
        <div className='w-full my-5 py-5 d-flex flex-column justify-content-center align-items-center'>
            <h1 className='orange-text fw-bold display-3'>404</h1>
            <h2 className='fw-normal display-5 mb-4 orange-text'>Not Found</h2>
            <Link to="/dashboard" className="theme-btn text-decoration-none">Go Back</Link>
        </div>
    )
}

export default ContentNotFound