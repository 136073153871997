import React, { memo, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./style.css";
import { addCartItem } from "../../store/features/cart/cartSlice";
import Img from "../lazyLoadImg/Img";

const ProductDetail = () => {
    const [singleProduct, setSingleProduct] = useState({});
    const [options, setOptions] = useState([]);
    const { productId } = useParams();
    const { products } = useSelector((state) => state.products);
    const { sides } = useSelector((state) => state.products);
    const { flavours } = useSelector((state) => state.products);
    const { productsOptions } = useSelector((state) => state.products);
    const { extras } = useSelector((state) => state.products);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // find product from given params id and setting it to the single product

    useEffect(() => {
        if (productId && products) {
            setSingleProduct(products.find((product) => product.pid === productId));
        }
    }, [products, productId]);

    // logic to set options if product has options element

    useEffect(() => {
        if (productsOptions) {
            let options = productsOptions.data.find(
                (item) => +item.id === +singleProduct.options
            );
            if (options) {
                let optionsArr = options.options
                    .replace(/"/g, "")
                    .slice(1, -1)
                    .split(",");
                setOptions(optionsArr);
            }
        }
    }, [singleProduct, productsOptions]);

    const [selectedSides, setSelectedSides] = useState([]); // State to track selected sides
    const [selectedFlavours, setSelectedFlavours] = useState([]); // State to track selected flavours
    const [quantity, setQuantity] = useState(1); // State to track quantity
    const [selectedOption, setSelectedOption] = useState(""); //state to track selected option
    const [price, setPrice] = useState(""); // state to set prices on sides seelction or other

    // to set price on sides selction

    useEffect(() => {
        setPrice(singleProduct.pprice);
        if (singleProduct.min_sides === null) {
            let sidePrice =
                extras.length > 0 &&
                extras.find((item) => item.ename === `side${selectedSides.length}`);
            if (sidePrice) {
                setPrice((prev) =>
                    (Number(prev) + Number(sidePrice.evalue)).toFixed(2)
                );
            }
        }
    }, [singleProduct, selectedSides, extras]);

    // Function to handle decreasing quantity
    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    // Function to handle increasing quantity
    const increaseQuantity = () => {
        setQuantity(quantity + 1);
    };

    // Function to handle selecting or deselecting a side
    const handleSideSelection = (sideName) => {
        const index = selectedSides.indexOf(sideName);
        if (index === -1) {
            setSelectedSides([...selectedSides, sideName]);
        } else {
            setSelectedSides(selectedSides.filter((id) => id !== sideName));
        }
    };

    // Function to handle selecting or deselecting a flavour
    const handleFlavourSelection = (flavourId) => {
        const index = selectedFlavours.indexOf(flavourId);
        if (index === -1) {
            setSelectedFlavours([...selectedFlavours, flavourId]);
        } else {
            setSelectedFlavours(selectedFlavours.filter((id) => id !== flavourId));
        }
    };

    // handle side when to  disabled

    const handleSideDisabled = (sideName) => {
        const { min_sides } = singleProduct;
        if (
            min_sides === null &&
            selectedSides.length === 2 &&
            !selectedSides.includes(sideName)
        ) {
            return true;
        }
        if (
            selectedSides.length === +singleProduct.min_sides &&
            !selectedSides.includes(sideName) &&
            min_sides !== null
        ) {
            return true;
        }
    };

    // handle flavour when to disable selection

    const handleFlavourDisabled = (flavourName) => {
        const { min_flavours } = singleProduct;
        if (
            min_flavours === null &&
            selectedFlavours.length === 2 &&
            !selectedFlavours.includes(flavourName)
        ) {
            return true;
        }
        if (
            selectedFlavours.length === +singleProduct.min_flavours &&
            !selectedFlavours.includes(flavourName) &&
            min_flavours !== null
        ) {
            return true;
        }
    };

    // Function to handle adding to cart
    const addToCart = () => {
        const requiredSides =
            singleProduct.min_sides !== null ? singleProduct.min_sides : 0;
        const requiredFlavours =
            singleProduct.min_flavours !== null ? singleProduct.min_flavours : 0;

        if (
            (selectedSides.length === +requiredSides ||
                singleProduct.min_sides === null) &&
            (selectedFlavours.length === +requiredFlavours ||
                singleProduct.min_flavours === null)
        ) {
            if (options.length > 0 && !selectedOption) {
                toast.error(`Please select a option`, {
                    position: "top-center",
                    theme: "dark",
                    autoClose: 2300,
                });
                return;
            }

            // adding product in cart

            dispatch(
                addCartItem({
                    id: singleProduct.pid,
                    q: quantity,
                    name: singleProduct.pname,
                    up: singleProduct.pprice,
                    p: price * quantity,
                    selectedFlavours,
                    selectedSides,
                    option: selectedOption,
                    iid: singleProduct.iid,
                    rqty: 0,
                    isDrink: singleProduct.isdrink,
                    minSides: singleProduct.min_sides,
                    minFlavours: singleProduct.min_flavours,
                    type: singleProduct.type,
                    img: singleProduct.pimg,
                    ticket: singleProduct.Ticket,
                })
            );

            console.log("Product added to cart successfully!");
            navigate("/dashboard/cart");
        } else {
            toast.error(
                `Please select ${requiredSides} side and ${requiredFlavours} flavour.`,
                {
                    position: "top-center",
                    theme: "dark",
                    autoClose: 2300,
                }
            );
        }
    };

    return singleProduct && productId && sides && flavours ? (
        <div className="product-detail">
            <div className="row align-items-start align-items-center">
                <div className="col-lg-3 mb-4 mb-lg-0">
                    <div className="product-img">
                        <Img
                            src={singleProduct.pimg}
                            alt={singleProduct.pname}
                            className="img-fluid"
                        />
                    </div>
                </div>
                <div className="col-lg-9">
                    <h4 className="pd-title">{singleProduct.pname}</h4>
                    {singleProduct && singleProduct.pprice && (
                        <p className="pd-price fw-medium">Price: £{price}</p>
                    )}
                </div>
                <div className="d-flex flex-wrap flex-column align-items-start mb-3">
                    {singleProduct.Ticket > 0 && (
                        <div className="pd-sides mb-2">
                            <h4 className="fw-normal mt-4">
                                Choose Your{" "}
                                {singleProduct.min_sides > 0 && singleProduct.min_sides} Side
                            </h4>
                            <div className="btn-group d-flex flex-wrap" role="group">
                                {sides.map((side) => (
                                    <React.Fragment key={side.sid}>
                                        <input
                                            className="btn-check"
                                            type="checkbox"
                                            id={side.sid + side.sname}
                                            name="sides"
                                            autoComplete="off"
                                            onChange={() => handleSideSelection(side.sname)}
                                            disabled={handleSideDisabled(side.sname)}
                                        />
                                        <label
                                            className="btn btn-sm btn-secondary m-2 ms-0"
                                            htmlFor={side.sid + side.sname}
                                        >
                                            {side.sname}
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    )}
                    {singleProduct.flavour > 0 && (
                        <div className="pd-flavours mb-2">
                            <h4 className="fw-normal mt-4">
                                Choose Your{" "}
                                {singleProduct.min_flavours > 0 && singleProduct.min_flavours}{" "}
                                Flavour
                            </h4>
                            <div className="btn-group d-flex flex-wrap" role="group">
                                {flavours.map((flavour) => (
                                    <React.Fragment key={flavour.fid}>
                                        <input
                                            className="btn-check"
                                            type="checkbox"
                                            id={flavour.fid + flavour.fname}
                                            name="flavours"
                                            autoComplete="off"
                                            onChange={() => handleFlavourSelection(flavour.fname)}
                                            disabled={handleFlavourDisabled(flavour.fname)}
                                        />
                                        <label
                                            className="btn btn-sm btn-secondary m-2 ms-0"
                                            htmlFor={flavour.fid + flavour.fname}
                                        >
                                            {flavour.fname}
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    )}
                    {options && options.length > 0 && (
                        <div className="pd-flavours mb-2">
                            <h4 className="fw-normal mt-4">Choose Your Option</h4>
                            <div className="btn-group d-flex flex-wrap" role="group">
                                {options.map((option) => (
                                    <React.Fragment key={option}>
                                        <input
                                            className="btn-check"
                                            type="radio"
                                            id={option}
                                            name="options"
                                            autoComplete="off"
                                            onChange={() => setSelectedOption(option)}
                                        />
                                        <label
                                            className="btn btn-sm btn-secondary m-2 ms-0"
                                            htmlFor={option}
                                        >
                                            {option}
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="bottom-sec d-flex justify-content-end align-items-center">
                    <div className="">
                        <button
                            className="btn btn-secondary me-2"
                            onClick={decreaseQuantity}
                        >
                            -
                        </button>
                        <span className="me-2">{quantity}</span>
                        <button
                            className="btn btn-secondary me-2"
                            onClick={increaseQuantity}
                        >
                            +
                        </button>
                    </div>
                    <button className="theme-btn me-2" onClick={addToCart}>
                        Add to Cart
                    </button>
                    <Link to="/dashboard" className="btn btn-secondary">
                        Cancel
                    </Link>
                </div>
            </div>
        </div>
    ) : (
        <div className="d-flex w-full vh-50 justify-content-center align-items-center">
            <h2 className="orange-text fw-normal">No Product Found</h2>
        </div>
    );
};

export default memo(ProductDetail);
