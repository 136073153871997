import React from 'react';
import { Layout, theme } from 'antd';
import Topbar from './topbar/Topbar';
import { Routes, Route, Navigate } from 'react-router-dom';
import Register from '../../components/register/Register';
import Signin from '../../components/signin/Signin';
import ForgotPassword from "../../components/forgotPassword/ForgotPassword"
import ProductDetail from '../../components/productDetail/ProductDetail';
import ContentNotFound from '../../components/contentNotFound/ContentNotFound';
import Cart from '../../components/cart/Cart';
import SideBar from './sidebar/SideBar';
import CheckOut from '../../components/checkout/CheckOut';
import { useSelector } from 'react-redux';
import MyOrders from '../../components/myOrders/MyOrders';
import ProductList from '../../components/productList/ProductList';
import NewPassword from '../../components/newPassword/NewPassword';

const { Content } = Layout;

const Dashboard = () => {

  const { logedInUser, myOrders } = useSelector((state) => state.user)

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout hasSider>
      <div id="fullScreenSidebar"><SideBar /></div>
      {/* <SideBar /> */}
      <Layout
        id='dashboardLayout'
        style={{
          marginLeft: 250,
        }}
      >
        <Topbar />
        <Content
          style={{
            margin: '80px 16px 0',
            overflow: 'initial',

          }}
        >
          <div
            className='text-white main-content'
            style={{
              padding: 24,
              background: "#1F1F1F",
              borderRadius: borderRadiusLG,
              marginBottom: "30px",

            }}
          >
            <Routes>
              <Route path='/' element={<ProductList />} />
              <Route path='/register' element={<Register />} />
              <Route path='/signin' element={<Signin />} />
              <Route path='/forgotpassword' element={<ForgotPassword />} />
              <Route path='/product/:productId' element={<ProductDetail />} />
              <Route path='/cart' element={<Cart />} />
              <Route path='/checkout' element={logedInUser ? <CheckOut /> : <Navigate to="/dashboard" />} />
              <Route path='/myorders' element={myOrders && myOrders.length > 0 ? <MyOrders/> : <Navigate to="/dashboard"/>} />
              <Route path='/newpassword' element={<NewPassword />} />
              <Route path='*' element={<ContentNotFound />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
