import React, { useState } from 'react';
import "./style.css"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(`${BASE_URL}/forgetpassword.php`, { email });
            if (response.status === 200) {
                toast.success(`Check Your Email`, {
                    position: 'top-center',
                    theme: "dark",
                    autoClose: 1300
                });
                navigate("/dashboard/newpassword");
            }
        } catch (error) {
            toast.error(`Invalid Email`, {
                position: 'top-center',
                theme: "dark",
                autoClose: 1300
            });
        }
    };

    return (
        <div className='forgot-password-form'>
            <h4 className='fw-normal'>Reset Password</h4>
            <div className="container my-5">
                <div className="row justify-content-center align-item-center">
                    <div className="col-md-6">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="theme-btn  mb-3 w-100">Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
