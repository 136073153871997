import { combineReducers, configureStore } from '@reduxjs/toolkit';
import productReducer from "./features/products/productSlice";
import categoryReducer from "./features/category/categorySlice";
import cartReducer from "./features/cart/cartSlice";
import userReducer from "./features/user/userSlice"
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ['cart', 'user'] // Specify the states you want to persist
}

const rootReducer = combineReducers({
    products: productReducer,
    category: categoryReducer,
    cart: cartReducer,
    user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
