import React, { useEffect, useState } from 'react';
import CartItem from '../cartItem/CartItem';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { IoBagCheckOutline } from "react-icons/io5";
import { toast } from 'react-toastify';

const Cart = () => {

    const { cartItems } = useSelector((state) => state.cart);
    const [totalPrice, setTotalPrice] = useState(0);
    const { logedInUser } = useSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        let billAmount = 0;
        cartItems.forEach((cart) => {
            billAmount += cart.p;
        })
        setTotalPrice(billAmount.toFixed(2));
    }, [cartItems]);

    const handleCheckOUt = () => {
        if (logedInUser) {
            navigate("/dashboard/checkout");
        } else {
            toast.error(`Please Login First`, {
                position: 'top-center',
                theme: "dark",
                autoClose: 1300
            });
        }
    }

    if (cartItems && cartItems.length !== 0) {
        return (
            <div className="cart">
                <h4 className='fw-normal'>Cart</h4>
                <div className="container pb-5 pt-3">
                    {cartItems.map((cart) => (
                        <CartItem key={cart.id} cart={cart} />
                    ))}
                </div>
                <div className="d-flex justify-content-center">
                    <div className="card" style={{ width: '300px' }}>
                        <div className="card-body">
                            <h5 className="card-title">Cart Summary</h5>
                            <hr />
                            <p>Total Items: <strong>{cartItems.length}</strong></p>
                            <p>Total Price: <strong>£{totalPrice}</strong></p>
                            <button className="btn btn-success w-100 d-flex align-items-center justify-content-center" onClick={handleCheckOUt}><IoBagCheckOutline className='me-2' /><span>Checkout</span></button>
                        </div>
                    </div>
                </div>
            </div>

        );
    } else {
        return (
            <div className="py-5 text-center">
                <h2 className="fw-bold orange-text mb-3">Empty Cart</h2>
                <Link
                    to="/dashboard"
                    className="theme-btn text-decoration-none text-white py-1"
                >
                    Shop Now
                </Link>
            </div>
        )
    }
}

export default Cart;
