import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import ProductCard from "../productCard/ProductCard"

const ProductList = () => {

    const { products } = useSelector((state) => state.products);
    const { activeCategory } = useSelector((state) => state.category);

    return (
        <>
            <h4 className='fw-normal'>{activeCategory && activeCategory.catName}</h4>
            <div className="row">
                {products.length > 0 && products.map((product) => {
                    if (product.cid === activeCategory.catId) {
                        return (
                            <div className="col-lg-6" key={product.pid}>
                                <ProductCard product={product} />
                            </div>
                        )
                    }
                    return null
                }
                )}
            </div>
        </>
    )
}

export default memo(ProductList);
