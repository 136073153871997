import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchDataFromApi = async (endPoints, params) => {
    try {
        const { data } = await axios.get(BASE_URL + endPoints, {
            params
        });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
}
