import React from 'react'
import loading from "../../assets/images/loading.gif"

const Preloader = () => {
    return (
        <div className='vh-100 w-full d-flex justify-content-center align-items-center'>
            <img src={loading} alt="loading" width={100} />
        </div>
    )
}

export default Preloader