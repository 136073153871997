import React, { useState } from 'react'
import "./style.css"
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Register = () => {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        f: '',
        l: '',
        e: '',
        m: '',
        ps: '',
        a: '',
        p: '',
        d: '',
        s: '',
        c: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        try {
            // Add your form submission logic here
            let configs = {
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.post(`${BASE_URL}/customerreg.php`, formData, configs);
            // assuming API returns some data upon successful registration

            if (response.data && response.data.message) {
                toast.success(response.data.message, {
                    position: 'top-center',
                    theme: "dark",
                    autoClose: 1300
                });
                navigate("/dashboard/signin");
            }

            // Reset form data after successful registration
            setFormData({
                f: '',
                l: '',
                e: '',
                m: '',
                ps: '',
                a: '',
                p: '',
                d: '',
                s: '',
                c: ''
            });
        } catch (error) {
            toast.error(error.message, {
                position: 'top-center',
                theme: "dark",
                autoClose: 1300
            });
            console.error('Registration failed:', error);
            // Handle registration failure, show error message to the user, etc.
        }

    };



    return (
        <form onSubmit={handleSubmit} className='register-form'>
            <h4 className='fw-normal'>Register</h4>
            <p className='form-sec-title'>ENTER DETAILS</p>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">First Name *</label>
                        <input placeholder='First Name' type="text" className="form-control" id="firstName" name="f" value={formData.f} onChange={handleChange} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">Last Name *</label>
                        <input placeholder='Last Name' type="text" className="form-control" id="lastName" name="l" value={formData.l} onChange={handleChange} required />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email Address *</label>
                        <input placeholder='Email Address' type="email" className="form-control" id="email" name="e" value={formData.e} onChange={handleChange} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="mobileNumber" className="form-label">Mobile Number *</label>
                        <input placeholder='Mobile Number' type="text" className="form-control" id="mobileNumber" name="m" value={formData.m} onChange={handleChange} required />
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password *</label>
                        <input placeholder='Password' type="password" className="form-control" id="password" name="ps" value={formData.ps} onChange={handleChange} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">Address *</label>
                        <input placeholder='Address' type="text" className="form-control" id="address" name="a" value={formData.a} onChange={handleChange} required />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="postalCode" className="form-label">Postal Code *</label>
                        <input placeholder='Postal Code' type="text" className="form-control" id="postalCode" name="p" value={formData.p} onChange={handleChange} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="streetName" className="form-label">Date Of Brith *</label>
                        <input placeholder='Street Name' type="date" className="form-control" id="streetName" name="d" value={formData.d} onChange={handleChange} required />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="gender" className="form-label">Gender *</label>
                        <select className="form-select bg-transparent text-white" id="gender" name="s" value={formData.s} onChange={handleChange} required>
                            <option value="" className='text-dark'>Select Gender</option>
                            <option value="male" className='text-dark'>Male</option>
                            <option value="female" className='text-dark' >Female</option>
                            <option value="other" className='text-dark'>Other</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="country" className="form-label">Country *</label>
                        <input placeholder='Country' type="text" className="form-control" id="country" name="c" value={formData.c} onChange={handleChange} required />
                    </div>
                </div>
            </div>
            <button type="submit" className="theme-btn w-100">Register</button>
        </form>
    );
}

export default Register