// useFetch.js
import { useEffect, useState } from 'react';
import { fetchDataFromApi } from '../utils/api';

const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setData(null);
            setError(null);

            try {
                const resp = await fetchDataFromApi(url);
                setData(resp);
            } catch (error) {
                setError("Something went wrong: " + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, [url]);

    return { data, loading, error };
};

export default useFetch;
