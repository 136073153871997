import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const PageNotFound = () => {

    const navigate = useNavigate();

    return (
        <div className='w-full vh-100 d-flex flex-column justify-content-center align-items-center'>
            <h1 className='orange-text fw-bold display-3'>404</h1>
            <h2 className='fw-normal display-5 mb-4 orange-text'>Not Found</h2>
            <Link onClick={() => navigate(-1)} className="theme-btn text-decoration-none">Go Back</Link>
        </div>
    )
}

export default PageNotFound