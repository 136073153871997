import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeCartItem, setCartItem, setOrderType } from "../../store/features/cart/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { setMyOrders } from "../../store/features/user/userSlice";

const CheckOut = () => {

    const { cartItems, orderType } = useSelector((state) => state.cart);
    const [totalAmount, setTotalAmount] = useState("");
    const [checkoutInfo, setCheckoutInfo] = useState([]);
    const { logedInUser } = useSelector((state) => state.user);
    const [loading, setLoading] = useState();;
    const navigate = useNavigate();

    useEffect(() => {
        let amount = 0;
        cartItems.forEach((item) => {
            amount += item.p;
        });
        setTotalAmount(amount.toFixed(2));
    }, [cartItems]);

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        cardNumber: "",
        expiryMonth: "",
        expiryYear: "",
        cvc: "",
        instruction: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    // setting checkout infromation in the states for post api
    useEffect(() => {
        let newInfo = cartItems.map((item) => {
            let sides = "";
            if (item.selectedSides) {
                sides = item.selectedSides
                    .map((side) => `</br><span class=side>${side}</span>`)
                    .join("");
            }
            let flavors = "";
            if (item.selectedFlavours) {
                flavors = item.selectedFlavours.map((flavor) => `${flavor},`).join("");
                flavors = flavors ? `(${flavors})` : flavors;
            }
            let nameWithSidesFlavoursOption = `${item.name}${flavors}${sides}<br><span class=option>${item.option}</span>`;
            return {
                id: item.id,
                q: item.q,
                name: nameWithSidesFlavoursOption.toString(),
                up: item.up,
                p: item.p,
                iid: item.iid,
                rqty: item.rqty,
                ticket: item.ticket,
                isdrink: item.isDrink,
                option: "",
                minSides: item.minSides,
                minflavours: item.minFlavours,
                type: item.type,
            };
        });
        console.log(newInfo);
        setCheckoutInfo(newInfo);
    }, [cartItems]);

    // method to place order 

    const placeOrder = async (e) => {
        setLoading(true);
        e.preventDefault();
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const userID = logedInUser.id;
        const postalCode = logedInUser.postalCode;
        const isDelivery = orderType === "delivery";
        const isCollection = orderType === "collection";

        // Convert checkoutInfo array to a string
        let checkoutInfoString = JSON.stringify(checkoutInfo);

        // Construct the data object to be sent in the POST request
        const requestData = {
            amount: totalAmount, // Assuming totalAmount is calculated correctly
            billdetails: checkoutInfoString, // Send the stringified checkoutInfo
            userid: userID,
            postalcode: postalCode,
            is_delivery: isDelivery,
            is_collection: isCollection,
            instruction: formData.instruction
        };

        console.log(requestData);
        try {
            const response = await axios.post(`${BASE_URL}/bill.php`, requestData);
            if (response.status === 200) {
                // Fetch updated orders after placing the order
                const { data: updatedOrders } = await axios.get(`${BASE_URL}/getbillsbyclient.php?id=${logedInUser.id}`);
                // Dispatch an action to update myOrders state in Redux
                dispatch(setMyOrders(updatedOrders.data));
                // making empty cart on order done 
                dispatch(setCartItem([]));
                // Redirecting user to dashboard
                navigate("/dashboard/myorders");
                // Handle success response here
                toast.success(`Order placed successfully:`, {
                    position: 'top-center',
                    theme: "dark",
                    autoClose: 1300
                });
                setLoading(false);
            } else {
                console.error('Failed to place order:');
                // Handle error response here
            }
        } catch (error) {
            console.error('Error while placing order:');
            // Handle network or other errors here
        }
        setLoading(false)
    };


    return (
        <div className="checkoutPage pb-5">
            <h4 className="fw-normal">Checkout</h4>
            {cartItems.length > 0 ? (
                <div className="row">
                    <div className="col-lg-5">
                        {cartItems &&
                            cartItems.length > 0 &&
                            cartItems.map((item) => (
                                <div key={item.id} className="card mb-2 text-white bg-orange">
                                    <div className="card-body">
                                        <h5 className="card-title">{item.name}</h5>
                                        <div className="d-flex mt-2">
                                            <p className="card-text m-0 mb-1 me-3">
                                                Quantity : <b>{item.q}</b>
                                            </p>
                                            <p className="card-text m-0 mb-1 me-3">
                                                Unit Price : <b>£{item.up}</b>
                                            </p>
                                            <p className="card-text m-0 mb-1">
                                                Price : <b>£{(item.p).toFixed(2)}</b>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button
                                                onClick={() => dispatch(removeCartItem(item.id))}
                                                className="btn btn-dark btn-sm mt-2"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-6">
                        <h4 className="fw-normal mb-3 lh-1">Total Items : {cartItems.length}</h4>
                        <h4 className="fw-normal mb-3">Total Amount : £{totalAmount}</h4>
                        <form onSubmit={placeOrder}>
                            <div className="mb-3">
                                <label htmlFor="instruction" className="form-label">
                                    Order Instruction
                                </label>
                                <textarea
                                    className="form-control"
                                    id="instruction"
                                    name="instruction"
                                    value={formData.instruction}
                                    onChange={handleChange}
                                    placeholder="Any Instruction?"
                                    required></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="optionSelect" className="form-label">Order Type</label>
                                <select className="form-select" id="optionSelect" onChange={(e) => dispatch(setOrderType(e.target.value))}>
                                    <option value="collection" selected={orderType === "collection"}>Collection</option>
                                    <option value="delivery" selected={orderType === "delivery"}>Delivery</option>
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="card-number" className="form-label">
                                    Card Number
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="card-number"
                                    name="cardNumber"
                                    value={formData.cardNumber}
                                    onChange={handleChange}
                                    placeholder="1234 5678 9012 3456"
                                    maxLength="19"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Expiration Date</label>
                                <div className="row">
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="expiry-month"
                                            name="expiryMonth"
                                            value={formData.expiryMonth}
                                            onChange={handleChange}
                                            placeholder="MM"
                                            maxLength="2"
                                            required
                                        />
                                    </div>
                                    <div className="col">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="expiry-year"
                                            name="expiryYear"
                                            value={formData.expiryYear}
                                            onChange={handleChange}
                                            placeholder="YY"
                                            maxLength="2"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="cvc" className="form-label">
                                    CVC
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cvc"
                                    name="cvc"
                                    value={formData.cvc}
                                    onChange={handleChange}
                                    placeholder="123"
                                    maxLength="3"
                                    required
                                />
                            </div>
                            <button type="submit" className="theme-btn">
                                {loading ? "Loading..." : "Place Order"}
                            </button>
                        </form>
                    </div>
                </div>
            ) : (
                <div className="py-5 text-center">
                    <h2 className="fw-bold orange-text mb-3">Empty Cart</h2>
                    <Link
                        to="/dashboard"
                        className="theme-btn text-decoration-none text-white py-1"
                    >
                        Shop Now
                    </Link>
                </div>
            )}
        </div>
    );
};

export default CheckOut;
