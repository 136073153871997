import React from 'react';
import { Tab, Nav, Form, Button } from 'react-bootstrap';
import { FaTruck } from 'react-icons/fa';
import { ImSpoonKnife } from 'react-icons/im';
import logo from '../../assets/images/Color-logo.png';
import './style.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOrderType } from '../../store/features/cart/cartSlice';

const Welcome = () => {

    const dispatch = useDispatch();

    return (
        <div className="welcomeScreen vh-100 w-full d-flex align-items-center justify-content-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-8 col-sm-10">
                        <div className="text-center my-5">
                            <img src={logo} width={300} alt="Logo" />
                        </div>
                        <div className="form-sec">
                            <Tab.Container defaultActiveKey="delivery" className="text-center">
                                <Nav variant="tabs" className="justify-content-center mb-4">
                                    <Nav.Item>
                                        <Nav.Link eventKey="delivery" className="nav-link">
                                            <FaTruck /> Delivery
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="collection" className="nav-link">
                                            <ImSpoonKnife /> Collection
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="delivery">
                                        <Form>
                                            <Form.Group controlId="deliveryInput">
                                                <Form.Control type="text" placeholder="Postal Code" />
                                            </Form.Group>
                                            <Link to="/dashboard">
                                                <Button onClick={()=> dispatch(setOrderType("delivery"))} variant="primary" type="button" block="true">
                                                    Find My Store
                                                </Button>
                                            </Link>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="collection">
                                        <Form>
                                            <Link to="/dashboard">
                                                <Button onClick={()=> dispatch(setOrderType("collection"))} variant="primary" type="button" block="true">
                                                    Find My Location
                                                </Button>
                                            </Link>
                                        </Form>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
