import React from "react";
import { useDispatch } from "react-redux";
import { removeCartItem } from "../../store/features/cart/cartSlice";
import "./style.css"
import Img from "../lazyLoadImg/Img";
import { FaTrash } from 'react-icons/fa';

const CartItem = ({ cart }) => {

    const dispatch = useDispatch();
    const removeItem = (id) => {
        dispatch(removeCartItem(id));
    };

    return (
        <div className="card mb-3 cart-item p-3">
            <div className="row g-0 align-items-center">
                <div className="col-md-3 col d-flex align-items-center justify-content-center">
                    <Img
                        src={cart.img}
                        alt="Product"
                        className="img-fluid rounded-start"
                    />
                </div>
                <div className="col-md-9 col">
                    <div className="card-body row align-items-center">
                        <div className="col col-lg-6 col ps-4">
                            <h6 className="card-title">{cart.name}</h6>
                            <p className="card-text mb-1">
                                <small className="text-muted">Price: £{cart.up}</small>
                            </p>
                            {cart.selectedFlavours.length > 0 && (
                                <p className="card-text m-0">
                                    <small className="fw-bold">Flavours : </small>
                                    {cart.selectedFlavours.map((flavour, index) => (
                                        <small key={index} className="text-muted m-0 me-2">{flavour},</small>
                                    ))}
                                </p>
                            )}
                            {cart.selectedSides.length > 0 && (
                                <p className="card-text m-0">
                                    <small className="fw-bold">Sides : </small>
                                    {cart.selectedSides.map((side, index) => (
                                        <small key={index} className="text-muted m-0 me-2">{side},</small>
                                    ))}
                                </p>
                            )}
                            {cart.option && (
                                <p className="card-text m-0">
                                    <small className="fw-bold">Option : </small>
                                    <small className="text-muted m-0 me-2">{cart.option}</small>
                                </p>
                            )}
                        </div>
                        <div className="col-lg-2 col">
                            <p className="fw-medium m-0">Qty: {cart.q}</p>
                        </div>
                        <div className="col-lg-3 col">
                            <p className="fw-medium m-0">Total Price: £{cart.p.toFixed(2)}</p>
                        </div>
                        <div className="col-lg-1 col">
                            <span
                                onClick={() => removeItem(cart.id)}
                                className="text-danger h3 cursor-pointer lh-1 del-icon"
                            >
                                <FaTrash />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartItem;
