import React, { memo } from 'react';
import "./style.css"
import { Link } from 'react-router-dom';
import Img from '../lazyLoadImg/Img';

const ProductCard = ({ product }) => {


    return (
        <div className='product-card'>
            <div className="card mb-3 p-3">
                <div className="row no-gutters">
                    <div className="col-md-4">
                        <Img src={product.pimg} className="card-img" alt={product.pname} />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body p-0">
                            <h5 className="card-title">{product.pname}
                            </h5>
                            <p className="card-text price-text fw-medium orange-text">Price: £{product.pprice}</p>
                            <div className="d-flex justify-content-end">
                                <Link to={`/dashboard/product/${product.pid}`} className="theme-btn text-decoration-none text-white">Details</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(ProductCard);
