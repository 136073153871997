import React from 'react';
import { useSelector } from 'react-redux';
import "./style.css";

const MyOrders = () => {

    const { myOrders } = useSelector((state) => state.user);

    // Function to convert time from 24-hour format to 12-hour format with AM and PM
    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        const suffix = hours >= 12 ? 'PM' : 'AM';
        const convertedHours = hours % 12 || 12;
        return `${convertedHours}:${minutes} ${suffix}`;
    };


    return (
        <div className="container">
            <h4 className='fw-normal mb-3'>My Orders</h4>
            {myOrders && myOrders.map((order) => (
                <div className="row" key={order.bid}>
                    <div className="col-md-12">
                        <div className="order-card row">
                            <div className='col-lg-4'>
                                <h4>Order ID #{order.bid}</h4>
                                <p>
                                    Status: <span className="order-status text-success">{order.ostatus[0].toUpperCase() + order.ostatus.slice(1)}</span>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <p>Billing Date: {order.bdate}</p>
                                <p>Billing Time: {convertTo12HourFormat(order.btime)}</p>
                            </div>
                            <div className='col-lg-4 '>
                                <div dangerouslySetInnerHTML={{ __html: JSON.parse(order.bdetails).map((item, index) => `<p>Item : ${index + 1} ${item.name.split(/<br>|<\/br>|\(/)[0].trim()} <small>(Qty : ${item.q})</small></p>`).join('') }}></div>
                                <p className='h6 fw-medium'>Amount: £{order.bamount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MyOrders;
